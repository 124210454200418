import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import serviceblock from "../../data/service/service.json";


import Logo from "../../assets/img/axisdent/axisdent-logo-transparent.png"

class Footer extends Component {
    render() {
        return (
            <footer className="sigma_footer style-5 pb-0">
                <div className="container">
                    <div className="sigma_info-wrapper style-26 mb-5">
                        <div className="sigma_info style-26">
                            <div className="sigma_info-title">
                                <span className="sigma_info-icon">
                                    <i className="fal fa-map-marker-alt" />
                                </span>
                            </div>
                            <div className="sigma_info-description">
                                <p>Adresa noastră</p>
                                <p className="secondary-color"><b>Galati, Str. Barbosi Nr. 13A</b>
                                </p>
                            </div>
                        </div>
                        <div className="sigma_info style-26">
                            <div className="sigma_info-title">
                                <span className="sigma_info-icon">
                                    <i className="fal fa-phone" />
                                </span>
                            </div>
                            <div className="sigma_info-description">
                                <p>Telefon</p>
                                <p className="secondary-color"><b><a href="tel:+40 720 413 311" target="_blank">+40 720 413 311</a></b>
                                </p>
                            </div>
                        </div>
                        <div className="sigma_info style-26">
                            <div className="sigma_info-title">
                                <span className="sigma_info-icon">
                                    <i className="fal fa-envelope" />
                                </span>
                            </div>
                            <div className="sigma_info-description">
                                <p>Email</p>
                                <p className="secondary-color"><b><a href="mailto: abc@example.com" target="_blank">axisdent.galati@gmail.com</a></b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sigma_footer-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="sigma_footer-widget">
                                    <Link to="/">
                                    <div className="sigma_footer-logo mb-4">
                                        <img src={Logo} alt="logo" style={{width: "50px"}} />
                                        <span style={{textDecoration: "underline"}}>AxisDent</span>
                                    </div>
                                    </Link>
                                    <div className="row" >
                                        <div className="col-sm-9">
                                            <p className="mb-0">Calitate în îngrijirea dentară pentru un zâmbet de neuitat!</p>
                                        </div>
                                    </div>
                                    <ul className="sigma_social-icons has-border mt-4 justify-content-start">
                                        {/*<li>*/}
                                        {/*    <Link to="#"><i className="fab fa-facebook-f" /></Link>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <Link to="#"><i className="fab fa-twitter" /></Link>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <Link to="#"><i className="fab fa-instagram" /></Link>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <Link to="#"><i className="fab fa-linkedin" /></Link>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <Link to="#"><i className="fab fa-google" /></Link>*/}
                                        {/*</li>*/}
                                    </ul>
                                </div>
                            </div>
                            {/*<div className="col-lg-3 col-sm-3">*/}
                            {/*    <div className="sigma_footer-widget">*/}
                            {/*        <h5 className="widget-title">Services</h5>*/}
                            {/*        <ul className="sigma_footer-links">*/}
                            {/*            /!* Data *!/*/}
                            {/*            {serviceblock.slice(0, 5).map((item, i) => (*/}
                            {/*                <li key={i}>*/}
                            {/*                    <Link to={"/service-details/" + item.id}>{item.title}</Link>*/}
                            {/*                </li>*/}
                            {/*            ))}*/}
                            {/*            /!* Data *!/*/}
                            {/*        </ul>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="col-lg-8 col-sm-3">
                                <div className="sigma_footer-widget">
                                    <h5 className="widget-title">Link-uri Utile</h5>
                                    <ul style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end"}} className="sigma_footer-links">
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li>
                                            <Link to="/despre-noi">Despre noi</Link>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <Link to="/echipa-noastra">Echipa noastră</Link>*/}
                                        {/*</li>*/}
                                        <li>
                                            <Link to="/finantare-ue">Finantare UE</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">Contactează-ne</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/*<div className="col-lg-3 col-sm-6">*/}
                            {/*    <div className="sigma_footer-widget">*/}
                            {/*        <h5 className="widget-title">Subscribe</h5>*/}
                            {/*        <form>*/}
                            {/*            <input type="email" name="email" placeholder="Email" required />*/}
                            {/*            <button type="button" className="mt-3 btn-block">Subscribe</button>*/}
                            {/*            <p className="mb-0 mt-3">Get The Latest Updates via email. Any time you may unsubscribe</p>*/}
                            {/*        </form>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="sigma_footer-bottom d-block d-sm-flex align-items-center justify-content-between">
                        <div className="sigma_footer-copyright mt-0 mb-3 mb-sm-0">
                            <p className="mb-0">© AxisDent <span style={{fontWeight: "bold"}}>2023</span>
                            </p>
                        </div>
                        <ul className="sigma_footer-links">
                            <li>
                                <Link to="/politica-de-confidentialitate">Politica de confidențialitate</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
