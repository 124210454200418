import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import doctorpost from '../../../data/doctor/doctor.json';
import { Rating } from '../../../helper/helper';
import Sidebar from '../../layouts/Doctorsidebar';
import Pagination from "react-js-pagination";
import Finantare from "../../../assets/img/axisdent/acasa/finantare2.jpeg";

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: doctorpost,
            activePage: 1,
            itemPerpage: 3,
            favorite: false
        }
        this.favoriteTrigger = this.favoriteTrigger.bind(this);
    }
    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
    }
    favoriteTrigger(item) {
        this.setState({ favorite: item });
        if (this.state.favorite === item) {
            this.setState({ favorite: null })
        }
    }
    render() {
        return (
            <div className="sidebar-style-9">
                <div style={{marginTop: "-80px"}} className="section section-padding">
                    <div className="container">
                        {/*<div className="section-title centered">*/}
                        {/*    <span className="subtitle">Finanțare</span>*/}
                        {/*    <h3 className="title">Finanțare UE</h3>*/}
                        {/*</div>*/}
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                <img src={Finantare} style={{width: "600px", marginBottom: "40px"}} />
                            </div>
                            <p>
                                Axisdent by <strong>Dr. Ana S.R.L.</strong> este una din câștigătorii concursului de <strong>Planuri de Afaceri</strong> din cadrul proiectului cofinanțat din Fondul Social European prin Programul Operațional Capital Uman 2014-2020 ID:POCU/829/6/13/140412. Antreprenoriat inovativ pentru studenți, în regiunea Sud-Est, Antres 2.0.
                            </p>
                            <p>
                                Obiectivul specific fiind creșterea numărului absolvenților de învățământ terțiar universitar și non universitar care își găsesc un loc de muncă ca urmare a accesului la activități de învățare la un potențial loc de muncă / cercetare / inovare, cu accent pe sectoarele economice cu potențial competitiv identificate conform SNC și domeniile de specializare inteligentă conform SNCDI.
                            </p>
                            <p>
                                Proiectul este cofinanțat din Fondul Social European prin Programul Operațional Capital Uman 2014-2020, Axa prioritară 6 „Educație și competențe” în cuantum de 85% și Guvernul României în cuantum de 15%, contravaloarea acoperită din ajutorul de „minimis” a fost de: <strong>484.374,02 Lei</strong>.
                            </p>
                            <p>
                                Perioada și locul de implementere: 29.06.2022 - 28.06.2023 conform contract de subvenție nr.29382/29.06.2022, Regiunea Sud-Est.
                            </p>

                            {/* Data */}
                            {/*{testimonials.slice(0, 3).map((item, i) => (*/}
                            {/*<div className="col-lg-4 col-md-6">*/}
                            {/*    /!*{getAuthor(item.author).map((author, i) => (*!/*/}
                            {/*    <div  className="sigma_testimonial bg-gray style-13">*/}
                            {/*        <div className="sigma_testimonial-thumb">*/}
                            {/*            <img src={ProfileIcon} alt={"profile-icon"} />*/}
                            {/*            <span className="fas fa-quote-left sigma_testimonial-icon" />*/}
                            {/*        </div>*/}
                            {/*        <div className="sigma_testimonial-body">*/}
                            {/*            <div style={{ display: "flex", flexDirection: "column", justifyContent: "between"}}>*/}
                            {/*                <p style={{minHeight: "150px"}}>"Experiența mea la acest cabinet stomatologic a fost excelentă! Personalul a fost foarte amabil și profesionist."</p>*/}
                            {/*                <div  className="sigma_author-block">*/}
                            {/*                    <h5>{reviewsDates[0]}</h5>*/}
                            {/*                    /!*<span className="sigma_testimonial-category">{author.specialist}</span>*!/*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*// ))}*/}
                            {/*</div>*/}
                            {/*<div className="col-lg-4 col-md-6">*/}
                            {/*    /!*{getAuthor(item.author).map((author, i) => (*!/*/}
                            {/*    <div  className="sigma_testimonial bg-gray style-13">*/}
                            {/*        <div className="sigma_testimonial-thumb">*/}
                            {/*            <img src={ProfileIcon} alt={"profile-icon"} />*/}
                            {/*            <span className="fas fa-quote-left sigma_testimonial-icon" />*/}
                            {/*        </div>*/}
                            {/*        <div className="sigma_testimonial-body">*/}
                            {/*            <div style={{ display: "flex", flexDirection: "column", justifyContent: "between"}}>*/}
                            {/*                <p style={{minHeight: "150px"}}>"Am avut o experiență minunată la acest cabinet. De la primul contact cu recepția până la finalizarea tratamentului."</p>*/}
                            {/*                <div  className="sigma_author-block">*/}
                            {/*                    <h5>{reviewsDates[1]}</h5>*/}
                            {/*                    /!*<span className="sigma_testimonial-category">{author.specialist}</span>*!/*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*// ))}*/}
                            {/*</div>*/}
                            {/*<div className="col-lg-4 col-md-6">*/}
                            {/*    /!*{getAuthor(item.author).map((author, i) => (*!/*/}
                            {/*    <div  className="sigma_testimonial bg-gray style-13">*/}
                            {/*        <div className="sigma_testimonial-thumb">*/}
                            {/*            <img src={ProfileIcon} alt={"profile-icon"} />*/}
                            {/*            <span className="fas fa-quote-left sigma_testimonial-icon" />*/}
                            {/*        </div>*/}
                            {/*        <div className="sigma_testimonial-body">*/}
                            {/*            <div style={{ display: "flex", flexDirection: "column", justifyContent: "between"}}>*/}
                            {/*                <p style={{minHeight: "150px"}}>"Acest cabinet stomatologic merită toate cuvintele de laudă!"</p>*/}
                            {/*                <div  className="sigma_author-block">*/}
                            {/*                    <h5>{reviewsDates[2]}</h5>*/}
                            {/*                    /!*<span className="sigma_testimonial-category">{author.specialist}</span>*!/*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*// ))}*/}
                            {/*</div>*/}
                            {/*))}*/}
                            {/* Data */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;
